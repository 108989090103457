import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'


import Card from './layout/Card'
import { } from './dashboardActions';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from '../common/widget/valueBox'
import Row from '../common/layout/row'
import InfoBox from '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

import imagemUser from '../assets/images/user.jpg';

import { } from './dashboardActions';

import {
    getLista
} from './dashboardActions';
import row from '../common/layout/row';

class Dashboard extends Component {



    state = {

    }

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.getLista();
    }



    render() {
        console.log(this.props.lista);

                    let totalQuantidade = 0;
 



                    return (

                        <Content>

                            <Grid style={{ marginLeft: 15 }}>

                                <h3>
                                    Dashboard
                                </h3>
                            </Grid>

                            <Row>
                                {this.props.lista.map(item => (

                                    totalQuantidade += parseFloat(item.quantidade),

                                    <div class="col-lg-4 col-6" >

                                        <div class="small-box bg-info"  >
                                            <div class="inner" style={{ background: item.cor, color: '#fff' }}>
                                                <h3>{item.quantidade}</h3>
                                                {item.nome}
                                            </div>
                                            {/* <div class="icon">
                                                <i class={statusIcon ? statusIcon.icon : null} aria-hidden='true'></i>
                                            </div> */}
                                        </div>
                                    </div>



                                ))}
                            
                                <div class="col-lg-4 col-6" >

                                    <div class="small-box bg-info"  >
                                        <div class="inner" style={{ background: "#1ABC9C", color: '#fff' }}>
                                            <h3>  {FormatUtils.formatarValorTela(totalQuantidade)}</h3>
                                            Quantidade Total
                                        </div>
                                        {/* <div class="icon">
                                            <i class={statusIcon ? statusIcon.icon : null} aria-hidden='true'></i>
                                        </div> */}
                                    </div>
                                </div>

                            </Row>

                        </Content >
                    )







    }
}

function mapStateToProps(state) {
    return ({
        sessao: state.auth.usuario,
        lista: state.dashboard.lista,
        modoTela: state.relatorioFinanceiro.modoTela,
        listaStatusIcon: state.dashboard.listaStatusIcon,
	    filtro: state.dashboard.filtro,

    });
}
const mapDispatchToProps = dispatch => bindActionCreators({
    getLista
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
